import axios from "axios";
import moment from "moment";
const url =
  process.env.REACT_APP_URL ||
  "https://bookit-testing-backend.crunchyapps.com/api";
// const url = "https://bookit-testing-backend.crunchyapps.com/api";
// const url = "http://localhost:5000/api";

export const fetchUsersData = async () => {
  let usersUrl = `${url}/users/getUsers`;
  try {
    const {
      data: {
        Data: { users },
      },
    } = await axios.get(usersUrl, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      users,
    };
  } catch (error) {
    console.log(error);
  }
};

export const addUser = async (values) => {
  let usersUrl = `${url}/users/addUser`;
  try {
    const formData = new FormData();
    formData.append(
      "username",
      values.user.username ? values.user.username : ""
    );
    formData.append("email", values.user.email ? values.user.email : "");
    formData.append("address", values.user.address ? values.user.address : "");
    formData.append(
      "firstname",
      values.user.firstname ? values.user.firstname : ""
    );
    formData.append("lastname", values.user.lastname);
    formData.append("phone", values.user.phone ? values.user.phone : "");
    if (values.user.user_image != null) {
      formData.append(
        "user_image",
        values.user.user_image.file
          ? values.user.user_image.file
          : values.user.user_image
      );
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const { user } = await axios.post(usersUrl, formData, config);

    return {
      user,
    };
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = async (values, id) => {
  let usersUrl = `${url}/users/updateProfile`;
  console.log(values, id);
  try {
    const formData = new FormData();
    formData.append("_id", id);
    formData.append("device", 0);
    formData.append("username", values.username ? values.username : "");
    formData.append("email", values.email ? values.email : "");
    formData.append("address", values.address ? values.address : "");
    formData.append("firstname", values.firstname ? values.firstname : "");
    formData.append("lastname", values.lastname);
    formData.append("phone", values.phone ? values.phone : "");
    if (values.user_image != null) {
      formData.append(
        "user_image",
        values.user_image.file ? values.user_image.file : values.user_image
      );
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const {
      data: { Data },
    } = await axios.post(usersUrl, formData, config);

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getUser = async (user_id) => {
  let usersUrl = `${url}/users/detail`;
  console.log("Hey", user_id);
  try {
    const {
      data: { Data },
    } = await axios.post(
      usersUrl,
      {
        user_id: user_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchTimeslotData = async (values) => {
  let usersUrl = `${url}/organizations/timeslots`;
  try {
    const {
      data: { Data },
    } = await axios.post(
      usersUrl,
      {
        organizationID: values.organization,
        serviceOfferingID: values.service,
        date: moment(values.date).format("YYYY-MM-DD"),
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchTimelineData = async (values) => {
  let usersUrl = `${url}/organizations/timeline`;
  try {
    const {
      data: { Data },
    } = await axios.post(
      usersUrl,
      {
        organizationID: values.organization,
        date: values.date,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteUser = async (user_id) => {
  let baseUrl = `${url}/users/delete`;
  try {
    const { data } = await axios.post(
      baseUrl,
      {
        user_id: user_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkUsers = async (bulkIds) => {
  let baseUrl = `${url}/users/bulkDelete`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const activateBulkUsers = async (bulkIds) => {
  let baseUrl = `${url}/users/bulkActivate`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkCustomerAssets = async (bulkIds, action) => {
  let baseUrl = `${url}/customerasset/bulkAction`;
  // car type agr yaha sy remove kr dein gy to pichy sy jo arhi hai, wohi jaye gi,
  //according to imran sharif this type is always car, that's why i'm declare this static.
  // other wise, the dynamic type is here for send in body.
  const type = "car";
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
        type: type,
        action: action,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const activateBulkCustomerAssets = async (bulkIds, action) => {
  let baseUrl = `${url}/customerasset/bulkAction`;
  // car type agr yaha sy remove kr dein gy to pichy sy jo arhi hai, wohi jaye gi,
  //according to imran sharif this type is always car, that's why i'm declare this static.
  // other wise, the dynamic type is here for send in body.
  const type = "car";
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
        type: type,
        action: action,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};
export const deactivateBulkCustomerAssets = async (bulkIds, action) => {
  let baseUrl = `${url}/customerasset/bulkAction`;
  // car type agr yaha sy remove kr dein gy to pichy sy jo arhi hai, wohi jaye gi,
  //according to imran sharif this type is always car, that's why i'm declare this static.
  // other wise, the dynamic type is here for send in body.
  const type = "car";
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
        type: type,
        action: action,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deactivateBulkAssets = async (bulkIds) => {
  let baseUrl = `${url}/assets/bulkDeactivate`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deactivateBulkUsers = async (bulkIds) => {
  let baseUrl = `${url}/users/bulkDeactivate`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const loginUser = (userData) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}/users/login`, userData)
      .then((response) => response.data)
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const vehiclesData = async () => {
  let vehiclesUrl = `${url}/vehicles/getVehiclesData`;
  try {
    const {
      data: { Data },
    } = await axios.get(vehiclesUrl, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const addUserVehicle = async (values, user_id) => {
  let usersUrl = `${url}/users/addVehicle`;
  try {
    const { data } = await axios.post(usersUrl, {
      user_id: user_id,
      model: values.model,
      make: values.make,
      type: values.type,
      year: values.year,
      color: values.color,
      vin: values.vin,
      registration_number: values.registration_number,
      letter: values.letter,
      state: values.state,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const updateVehicle = async (values, user_id, vehicle_id) => {
  let usersUrl = `${url}/users/updateVehicle`;
  try {
    const { data } = await axios.post(usersUrl, {
      user_id: user_id,
      vehicle_id: vehicle_id,
      model: values.model,
      make: values.make,
      type: values.type,
      year: values.year,
      color: values.color,
      vin: values.vin,
      registration_number: values.registration_number,
      letter: values.letter,
      state: values.state,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteUserVehicle = async (vehicle_id, user_id) => {
  let baseUrl = `${url}/users/deleteVehicle`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        vehicle_id: vehicle_id,
        user_id: user_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchOrganizationsData = async () => {
  let organizationsUrl = `${url}/organizations/`;
  try {
    const {
      data: {
        Data: { organizations },
      },
    } = await axios.get(organizationsUrl, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      organizations,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchCustomerAssetsData = async () => {
  let customerAssetUrl = `${url}/customerasset/listing/`;
  try {
    const type = "admin";
    const {
      data: { Data },
    } = await axios.post(
      customerAssetUrl,
      {
        type: type,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchAssetsData = async () => {
  let organizationsUrl = `${url}/assets/getListing/`;
  try {
    const {
      data: { Data },
    } = await axios.get(organizationsUrl, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }

  // let assetsUrl = `${url}/assets/getListing`;
  // try {
  //   const {
  //     data: {
  //       Data: { assets },
  //     },
  //   } = await axios.get(assetsUrl, {
  //     headers: { "Content-Type": "application/json" },
  //   });

  //   console.log('assets', assets)
  //   return {
  //     assets,
  //   };
  // } catch (error) {
  //   console.log(error);
  // }
};
export const addOrganization = async (values) => {
  let organizationUrl = `${url}/organizations/add`;
  try {
    const formData = new FormData();
    formData.append(
      "business_title",
      values.organization.business_title
        ? values.organization.business_title
        : ""
    );
    formData.append(
      "email",
      values.organization.email ? values.organization.email : ""
    );
    formData.append(
      "address",
      values.organization.address ? values.organization.address : ""
    );
    formData.append(
      "business_description",
      values.organization.business_description
        ? values.organization.business_description
        : ""
    );
    formData.append(
      "business_hours",
      values.organization.business_hours
        ? values.organization.business_hours
        : ""
    );
    formData.append(
      "website",
      values.organization.website ? values.organization.website : ""
    );
    formData.append(
      "facebook",
      values.organization.facebook ? values.organization.facebook : ""
    );
    formData.append(
      "instagram",
      values.organization.instagram ? values.organization.instagram : ""
    );
    formData.append(
      "twitter",
      values.organization.twitter ? values.organization.twitter : ""
    );

    formData.append(
      "phone",
      values.organization.phone ? values.organization.phone : ""
    );

    formData.append(
      "lat",
      values.organization.lat ? values.organization.lat : ""
    );

    formData.append(
      "long",
      values.organization.long ? values.organization.long : ""
    );

    if (values.organization.logo != null) {
      formData.append(
        "logo",
        values.organization.logo.file
          ? values.organization.logo.file
          : values.organization.logo
      );
    }
    if (values.organization.banners != null) {
      if (values.organization.banners.fileList) {
        for (var i = 0; i < values.organization.banners.fileList.length; i++) {
          formData.append(
            "banners",
            values.organization.banners.fileList[i].originFileObj
          );
        }
      } else {
        formData.append("banners", values.organization.banners);
      }
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const { data } = await axios.post(organizationUrl, formData, config);
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const activateBulkAssets = async (bulkIds) => {
  let baseUrl = `${url}/assets/bulkActivate`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const customerAssetStatusChange = async (bulkId, status, type) => {
  let assetUrl = `${url}/customerasset/bulkAction`;
  try {
    const action = status == 1 ? "deactive" : "active";
    // car type agr yaha sy remove kr dein gy to pichy sy jo arhi hai, wohi jaye gi,
    //according to imran sharif this type is always car, that's why i'm declare this static.
    // other wise, the dynamic type is here for send in body.
    const type = "car";
    var { data } = await axios.post(
      assetUrl,
      {
        bulkIds: bulkId,
        action: action,
        type: type,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
    // const formData = new FormData();
    // formData.append("id", id ? id : "");
    // formData.append("is_active", status == 1 ? 0 : 1);

    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // };

    // const { data } = await axios.post(assetUrl, formData, config);

    // return {
    //   data,
    // };
  } catch (error) {
    console.log(error);
  }
};
export const assetStatusChange = async (id, status) => {
  let assetUrl = `${url}/assets/changeStatus`;
  try {
    const is_active = status == 1 ? 0 : 1;
    var { data } = await axios.post(
      assetUrl,
      {
        id: id,
        is_active: is_active,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
    // const formData = new FormData();
    // formData.append("id", id ? id : "");
    // formData.append("is_active", status == 1 ? 0 : 1);

    // const config = {
    //   headers: {
    //     "content-type": "multipart/form-data",
    //   },
    // };

    // const { data } = await axios.post(assetUrl, formData, config);

    // return {
    //   data,
    // };
  } catch (error) {
    console.log(error);
  }
};
export const addAsset = async (values) => {
  let assetUrl = `${url}/assets/addAsset`;
  try {
    const formData = new FormData();
    formData.append("name", values.name ? values.name : "");
    formData.append(
      "is_active",
      values.is_active == undefined || values.is_active == 1 ? 1 : 0
    );

    if (values.logo != null) {
      formData.append(
        "asset_logo",
        values.logo.file ? values.logo.file : values.logo
      );
    }
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const { data } = await axios.post(assetUrl, formData, config);
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const updateAsset = async (values, id) => {
  let assetUrl = `${url}/assets/addAsset`;
  try {
    const formData = new FormData();
    formData.append("name", values.name ? values.name : "");
    formData.append("id", values.uid ? values.uid : "");
    formData.append(
      "is_active",
      values.is_active == undefined || values.is_active == 1 ? 1 : 0
    );

    if (values.logo != null) {
      formData.append(
        "asset_logo",
        values.logo.file ? values.logo.file : values.logo
      );
    }

    const { data } = await axios.post(assetUrl, formData);
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const updateOrganization = async (values, id) => {
  let organizationUrl = `${url}/organizations/update`;
  console.log("values", values);
  try {
    const formData = new FormData();

    formData.append("_id", id);
    formData.append(
      "business_title",
      values.business_title ? values.business_title : ""
    );
    formData.append("email", values.email ? values.email : "");
    formData.append("address", values.address ? values.address : "");
    formData.append(
      "business_description",
      values.business_description ? values.business_description : ""
    );
    formData.append("business_hours", values.business_hours);
    formData.append("website", values.website ? values.website : "");
    formData.append("facebook", values.facebook ? values.facebook : "");
    formData.append("instagram", values.instagram ? values.instagram : "");
    formData.append("twitter", values.twitter ? values.twitter : "");
    formData.append("phone", values.phone ? values.phone : "");
    formData.append("service", values.service ? values.service : "");

    formData.append("lat", values.lat ? values.lat : "");

    formData.append("long", values.long ? values.long : "");

    if (values.options != null) {
      for (var i = 0; i < values.options.length; i++) {
        formData.append("options[]", values.options ? values.options[i] : "");
      }
    }

    if (values.logo != null) {
      formData.append(
        "logo",
        values.logo.file ? values.logo.file : values.logo
      );
    }

    if (values.banners != null) {
      if (values.banners.fileList) {
        for (var i = 0; i < values.banners.fileList.length; i++) {
          if (values.banners.fileList[i].originFileObj) {
            formData.append(
              "banners",
              values.banners.fileList[i].originFileObj
            );
          } else {
            formData.append("banners", values.banners.fileList[i]._id);
          }
        }
      } else {
        formData.append("banners", values.banners);
      }
    }

    if (values.deletedBannerIDS.length > 0) {
      for (var i = 0; i < values.deletedBannerIDS.length; i++) {
        formData.append(
          "deletedBannerIDS[]",
          values.deletedBannerIDS ? values.deletedBannerIDS[i] : []
        );
      }
    }

    console.log("formdata", formData);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const {
      data: { Data },
    } = await axios.post(organizationUrl, formData, config);

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getOrganization = async (id) => {
  let organizationUrl = `${url}/organizations/detail`;
  console.log("Hey", id);
  try {
    const {
      data: { Data },
    } = await axios.post(
      organizationUrl,
      {
        organization_id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getAsset = async (id) => {
  let assetUrl = `${url}/assets/detail`;
  try {
    const Data = await axios.post(
      assetUrl,
      {
        id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    const getAssetDetail = Data.data.Data.asset;

    return {
      getAssetDetail,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteOrganization = async (id) => {
  let baseUrl = `${url}/organizations/delete`;
  try {
    const { data } = await axios.post(
      baseUrl,
      {
        organization_id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteAsset = async (id) => {
  let baseUrl = `${url}/assets/deleteAsset`;

  try {
    const { data } = await axios.post(
      baseUrl,
      {
        id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteCustomerAsset = async (record) => {
  let baseUrl = `${url}/customerasset/delete`;
  try {
    const id = record._id;
    // const type = record.type.type;

    // car type agr yaha sy remove kr dein gy to pichy sy jo arhi hai, wohi jaye gi,
    //according to imran sharif this type is always car, that's why i'm declare this static.
    // other wise, the dynamic type is here for send in body.
    const type = "car";
    const { data } = await axios.post(
      baseUrl,
      {
        id: id,
        type: type,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkOrganizations = async (bulkIds) => {
  let baseUrl = `${url}/organizations/bulkDelete`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkAssets = async (bulkIds) => {
  let baseUrl = `${url}/assets/bulkDelete`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const activateBulkOrganizations = async (bulkIds) => {
  let baseUrl = `${url}/organizations/bulkActivate`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deactivateBulkOrganizations = async (bulkIds) => {
  let baseUrl = `${url}/organizations/bulkDeactivate`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

// Services Api's
export const fetchServicesData = async () => {
  let baseUrl = `${url}/services/get`;
  try {
    const {
      data: {
        Data: { services },
      },
    } = await axios.get(baseUrl, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      services,
    };
  } catch (error) {
    console.log(error);
  }
};

export const addService = async (values) => {
  let baseUrl = `${url}/services/add`;
  try {
    const { data } = await axios.post(baseUrl, {
      title: values.title,
      asset: values.asset,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const updateService = async (values, id) => {
  let baseUrl = `${url}/services/update`;
  try {
    const { data } = await axios.post(baseUrl, {
      _id: id,
      title: values.title,
      asset: values.asset,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getService = async (id) => {
  let baseUrl = `${url}/services/detail`;
  try {
    const {
      data: { Data },
    } = await axios.post(
      baseUrl,
      {
        service_id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteService = async (id) => {
  let baseUrl = `${url}/services/delete`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        service_id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkServices = async (bulkIds) => {
  let baseUrl = `${url}/services/deleteBulk`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const activateBulkServices = async (bulkIds) => {
  let baseUrl = `${url}/services/bulkActivate`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deactivateBulkServices = async (bulkIds) => {
  let baseUrl = `${url}/services/bulkDeactivate`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const categoriesData = async () => {
  let baseUrl = `${url}/services/getCategories`;
  try {
    const {
      data: { Data },
    } = await axios.get(baseUrl, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};
export const getAssets = async () => {
  let baseUrl = `${url}/assets/getListing`;
  try {
    const {
      data: { Data },
    } = await axios.get(
      baseUrl,
      {
        type: "service",
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const serviceOptionsData = async (service_id) => {
  let baseUrl = `${url}/services/getOptions`;
  try {
    const {
      data: { Data },
    } = await axios.post(baseUrl, {
      service_id: service_id,
    });

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getOption = async (id) => {
  let baseUrl = `${url}/services/detailOption`;
  try {
    const {
      data: { Data },
    } = await axios.post(
      baseUrl,
      {
        service_id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const addOption = async (values, service_id) => {
  let baseUrl = `${url}/services/addOption`;
  try {
    const { data } = await axios.post(baseUrl, {
      service_id: service_id,
      title: values.title,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const updateOption = async (values, service_id, option_id) => {
  let baseUrl = `${url}/services/updateOption`;
  try {
    const { data } = await axios.post(baseUrl, {
      title: values.title,
      service_id: service_id,
      option_id: option_id,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteServiceOption = async (option_id, service_id) => {
  let baseUrl = `${url}/services/deleteOption`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        option_id: option_id,
        service_id: service_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    console.log("Result", data);
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkOptions = async (bulkIds, service_id) => {
  let baseUrl = `${url}/services/deleteBulkOptions`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
        service_id: service_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const addOrganizationService = async (values, organization_id) => {
  let baseUrl = `${url}/organizations/addOrganizationService`;
  try {
    const { data } = await axios.post(baseUrl, {
      organization_id: organization_id,
      service: values.service,
      option: values.option,
      type: values.type,
      SLA_Time: values.SLA_Time,
      is_active: values.is_active,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const updateOrganizationService = async (
  values,
  organization_id,
  service_id
) => {
  let baseUrl = `${url}/organizations/updateOrganizationService`;
  try {
    const { data } = await axios.post(baseUrl, {
      organization_id: organization_id,
      organizationServiceID: service_id,
      service: values.service,
      option: values.option,
      type: values.type,
      SLA_Time: values.SLA_Time,
      is_active: values.is_active,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteOrganizationService = async (
  service_id,
  organization_id
) => {
  let baseUrl = `${url}/organizations/deleteOrganizationService`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        organization_id: organization_id,
        service_id: service_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    console.log("Result", data);
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkOrganizationService = async (
  bulkIds,
  organization_id
) => {
  let baseUrl = `${url}/organizations/deleteBulkOrganizationService`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
        organization_id: organization_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const activateBulkOrganizationServices = async (
  bulkIds,
  organization_id
) => {
  let baseUrl = `${url}/organizations/bulkActivateOrganizationServices`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
        organization_id: organization_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deactivateBulkOrganizationServices = async (
  bulkIds,
  organization_id
) => {
  let baseUrl = `${url}/organizations/bulkDeactivateOrganizationServices`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
        organization_id: organization_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchQueuesData = async (organization_id) => {
  let baseUrl = `${url}/organizations/getQueues`;
  try {
    const {
      data: { Data },
    } = await axios.post(baseUrl, {
      organization_id: organization_id,
    });

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getQueue = async (id) => {
  let baseUrl = `${url}/organizations/detailQueue`;
  try {
    const {
      data: { Data },
    } = await axios.post(
      baseUrl,
      {
        queue_id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const addQueue = async (values, organization_id) => {
  let baseUrl = `${url}/organizations/addQueue`;
  try {
    console.log("Values", values);
    const { data } = await axios.post(baseUrl, {
      organization: organization_id,
      title: values.title,
      start_time: values.start_time,
      end_time: values.end_time,
      interval: values.interval,
      serviceOfferings: values.serviceOfferings,
      is_active: values.is_active,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const updateQueue = async (values, queue_id, organization_id) => {
  let baseUrl = `${url}/organizations/updateQueue`;
  try {
    console.log("Values", values);
    const { data } = await axios.post(baseUrl, {
      queue_id: queue_id,
      organization: organization_id,
      title: values.title,
      start_time: values.start_time,
      end_time: values.end_time,
      interval: values.interval,
      serviceOfferings: values.serviceOfferings,
      is_active: values.is_active,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteQueue = async (queue_id, organization_id) => {
  let baseUrl = `${url}/organizations/deleteQueue`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        organization_id: organization_id,
        queue_id: queue_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    console.log("Result", data);
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkQueue = async (bulkIds, organization_id) => {
  let baseUrl = `${url}/organizations/deleteBulkQueue`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
        organization_id: organization_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return data;
  } catch (error) {
    console.log(error);
  }
};
export const activateBulkQueues = async (bulkIds) => {
  let baseUrl = `${url}/organizations/bulkActivateQueue`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deactivateBulkQueues = async (bulkIds) => {
  let baseUrl = `${url}/organizations/bulkDeactivateQueue`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchOrganizationRequestsData = async () => {
  let baseUrl = `${url}/organizations/getRequests`;
  try {
    const {
      data: {
        Data: { organizationRequests },
      },
    } = await axios.get(baseUrl, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      organizationRequests,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchOrganizationServicesAPI = async (
  selectedType,
  selectedOrganizationID
) => {
  let baseUrl = `${url}/organizations/getOrganizationServices`;
  try {
    var {
      data: { Data },
    } = await axios.post(baseUrl, {
      selectedType: selectedType,
      selectedOrganizationID: selectedOrganizationID,
      requestFrom: "web",
    });
    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const bookingsData = async () => {
  let baseUrl = `${url}/bookings/getListing`;
  try {
    const {
      data: { Data },
    } = await axios.get(baseUrl, {
      headers: { "Content-Type": "application/json" },
    });

    console.log(Data);
    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const getStatuses = async () => {
  let baseUrl = `${url}/bookings/getStatuses`;
  try {
    const {
      data: { Data },
    } = await axios.get(baseUrl, {
      headers: { "Content-Type": "application/json" },
    });

    console.log(Data);
    return {
      Data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBooking = async (booking_id) => {
  let baseUrl = `${url}/bookings/delete`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        booking_id: booking_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    console.log("Result", data);
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const bookingStatus = async (values) => {
  let baseUrl = `${url}/bookings/bookingStatus`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        booking_id: values.booking_id,
        auth_user_id: values.auth_user_id,
        status_id: values.status_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    console.log("Result", data);
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const changeRequestStatus = async (values, request_id) => {
  let baseUrl = `${url}/organizations/requestStatus`;
  try {
    const { data } = await axios.post(baseUrl, {
      request_id: request_id,
      request_status: values.request_status,
    });

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};
export const deleteOrganizationRequest = async (id) => {
  let baseUrl = `${url}/organizations/organizationRequestDelete`;
  try {
    const { data } = await axios.post(
      baseUrl,
      {
        request_id: id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkOrganizationRequests = async (bulkIds) => {
  let baseUrl = `${url}/organizations/bulkOrganizationRequestDelete`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteBulkBookings = async (bulkIds) => {
  let baseUrl = `${url}/bookings/bulkBookingsDelete`;
  try {
    var { data } = await axios.post(
      baseUrl,
      {
        bulkIds: bulkIds,
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const fetchAllNotifications = async () => {
  let notificationsUrl = `${url}/notifications/all_notifications`;
  try {
    const {
      data: {
        Data: { notifications },
      },
    } = await axios.get(notificationsUrl, {
      headers: { "Content-Type": "application/json" },
    });

    return {
      notifications,
    };
  } catch (error) {
    console.log(error);
  }
};

export const addNotification = async (values) => {
  let notificationsUrl = `${url}/bookings/generic_push`;
  try {
    console.log("Next Step VALUES are:", values);
    var { data } = await axios.post(
      notificationsUrl,
      {
        send_to: values.send_to ? values.send_to : "all",
        message: values.message ? values.message : "",
      },
      { headers: { "Content-Type": "application/json" } }
    );

    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};

export const deleteNotification = async (notification_id) => {
  console.log("Next :", notification_id);
  let baseUrl = `${url}/notifications/delete_notification`;
  try {
    const { data } = await axios.post(
      baseUrl,
      {
        notification_id: notification_id,
      },
      { headers: { "Content-Type": "application/json" } }
    );
    return {
      data,
    };
  } catch (error) {
    console.log(error);
  }
};
