import React, { createContext, useReducer } from "react";
import AppReducer from "./AppReducer";
import {
  fetchUsersData,
  getAssets,
  addUser,
  deleteUser,
  updateUser,
  getUser,
  deleteBulkUsers,
  activateBulkUsers,
  activateBulkAssets,
  deactivateBulkUsers,
  deactivateBulkAssets,
  deactivateBulkCustomerAssets,
  activateBulkCustomerAssets,
  vehiclesData,
  addUserVehicle,
  updateVehicle,
  deleteUserVehicle,
  addOrganization,
  addAsset,
  assetStatusChange,
  customerAssetStatusChange,
  fetchOrganizationsData,
  fetchAssetsData,
  fetchCustomerAssetsData,
  getOrganization,
  getAsset,
  updateOrganization,
  updateAsset,
  deleteOrganization,
  deleteAsset,
  deleteCustomerAsset,
  deleteBulkOrganizations,
  deleteBulkAssets,
  deactivateBulkOrganizations,
  activateBulkOrganizations,
  fetchServicesData,
  addService,
  updateService,
  deleteService,
  deleteBulkServices,
  deleteBulkCustomerAssets,
  activateBulkServices,
  deactivateBulkServices,
  getService,
  categoriesData,
  serviceOptionsData,
  getOption,
  addOption,
  updateOption,
  deleteServiceOption,
  deleteBulkOptions,
  addOrganizationService,
  updateOrganizationService,
  deleteOrganizationService,
  deleteBulkOrganizationService,
  activateBulkOrganizationServices,
  deactivateBulkOrganizationServices,
  fetchQueuesData,
  getQueue,
  addQueue,
  activateBulkQueues,
  deactivateBulkQueues,
  updateQueue,
  deleteQueue,
  deleteBulkQueue,
  fetchOrganizationRequestsData,
  fetchOrganizationServicesAPI,
  bookingsData,
  deleteBooking,
  getStatuses,
  bookingStatus,
  fetchTimelineData,
  fetchTimeslotData,
  changeRequestStatus,
  deleteOrganizationRequest,
  deleteBulkOrganizationRequests,
  deleteBulkBookings,
  fetchAllNotifications,
  addNotification,
  deleteNotification,
} from "../api";

//Initial State
const initialState = {
  loading: true,
  error: "",
  users: [],
};

//Create context
export const GlobalContext = createContext();

//Provider Component
export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  function fetchUsersProgress() {
    dispatch({
      type: "FETCH_USERS_PROGRESS",
    });
  }

  function fetchUsersProgressHide() {
    dispatch({
      type: "FETCH_USERS_PROGRESS_HIDE",
    });
  }

  function fetchError() {
    dispatch({
      type: "FETCH_ERROR",
    });
  }

  async function fetchUsersApi() {
    try {
      fetchUsersProgress();
      const usersData = await fetchUsersData();
      if (usersData) {
        fetchUsersProgressHide();
        return usersData.users;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function addUserApi(values) {
    try {
      fetchUsersProgress();
      const userData = await addUser(values);
      if (userData) {
        fetchUsersProgressHide();
        return userData;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function updateUserApi(values, id) {
    try {
      fetchUsersProgress();
      const userData = await updateUser(values, id);
      if (userData) {
        fetchUsersProgressHide();
        return userData;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchUserDetailApi(id) {
    try {
      fetchUsersProgress();
      const userData = await getUser(id);
      if (userData) {
        fetchUsersProgressHide();
        return userData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchUserDeleteApi(id) {
    try {
      fetchUsersProgress();
      const result = await deleteUser(id);

      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkUsersDeleteApi(bulkIds) {
    try {
      fetchUsersProgress();
      const usersData = await deleteBulkUsers(bulkIds);
      if (
        usersData.data &&
        usersData.data.Message == "Users deleted successfully !"
      ) {
        const result = await fetchUsersApi();
        return result;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkUsersActivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const usersData = await activateBulkUsers(bulkIds);
      if (usersData) {
        fetchUsersProgressHide();
        return usersData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkAssetsActivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const usersData = await activateBulkAssets(bulkIds);
      if (usersData) {
        fetchUsersProgressHide();
        return usersData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkUsersDeactivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const usersData = await deactivateBulkUsers(bulkIds);
      if (usersData) {
        fetchUsersProgressHide();
        return usersData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkcustomerAssetsDeactivateApi(bulkIds, action) {
    try {
      fetchUsersProgress();
      const usersData = await deactivateBulkCustomerAssets(bulkIds, action);
      if (usersData) {
        fetchUsersProgressHide();
        return usersData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkcustomerAssetsDeleteApi(bulkIds, action) {
    try {
      fetchUsersProgress();
      const usersData = await deleteBulkCustomerAssets(bulkIds, action);
      if (usersData) {
        fetchUsersProgressHide();
        return usersData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkcustomerAssetsActivateApi(bulkIds, action) {
    try {
      fetchUsersProgress();
      const usersData = await activateBulkCustomerAssets(bulkIds, action);
      if (usersData) {
        fetchUsersProgressHide();
        return usersData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkAssetsDeactivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const usersData = await deactivateBulkAssets(bulkIds);
      if (usersData) {
        fetchUsersProgressHide();
        return usersData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchVehiclesDataApi() {
    try {
      fetchUsersProgress();
      const fetchedVehiclesData = await vehiclesData();
      if (fetchedVehiclesData) {
        fetchUsersProgressHide();
        return fetchedVehiclesData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function addVehicleApi(values, user_id) {
    try {
      fetchUsersProgress();
      const result = await addUserVehicle(values, user_id);
      if (result) {
        fetchUsersProgressHide();
        return result.data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function updateVehicleApi(values, user_id, vehicle_id) {
    try {
      fetchUsersProgress();
      const result = await updateVehicle(values, user_id, vehicle_id);
      if (result) {
        fetchUsersProgressHide();
        return result.data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchVehicleDeleteApi(vehicle_id, user_id) {
    try {
      fetchUsersProgress();
      const result = await deleteUserVehicle(vehicle_id, user_id);
      console.log("result", result);
      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchOrganizationsApi() {
    try {
      fetchUsersProgress();
      const organizationsData = await fetchOrganizationsData();
      if (organizationsData) {
        fetchUsersProgressHide();
        return organizationsData.organizations;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function addOrganizationApi(values) {
    try {
      fetchUsersProgress();
      const result = await addOrganization(values);
      return result.data;
    } catch (error) {
      fetchError();
    }
  }

  async function customerAssetStatusChangeApi(bulkId, status, type) {
    try {
      fetchUsersProgress();
      const result = await customerAssetStatusChange(bulkId, status, type);
      return result.data;
    } catch (error) {
      fetchError();
    }
  }
  async function assetStatusChangeApi(id, status) {
    try {
      fetchUsersProgress();
      console.log("check id or status", id, status);
      const result = await assetStatusChange(id, status);
      return result.data;
    } catch (error) {
      fetchError();
    }
  }
  async function addAssetApi(values) {
    try {
      fetchUsersProgress();
      const result = await addAsset(values);
      return result.data;
    } catch (error) {
      fetchError();
    }
  }

  async function fetchcustomerAssetsApi() {
    try {
      fetchUsersProgress();
      const assetsData = await fetchCustomerAssetsData();
      if (assetsData) {
        fetchUsersProgressHide();
        return assetsData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchAssetsApi() {
    try {
      fetchUsersProgress();
      const assetsData = await fetchAssetsData();
      if (assetsData) {
        fetchUsersProgressHide();
        return assetsData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function updateOrganizationApi(values, id) {
    try {
      fetchUsersProgress();
      await updateOrganization(values, id);
    } catch (error) {
      fetchError();
    }
  }

  async function updateAssetApi(values, id) {
    try {
      fetchUsersProgress();
      await updateAsset(values, id);
    } catch (error) {
      fetchError();
    }
  }

  async function fetchOrganizationDetailApi(id) {
    try {
      fetchUsersProgress();
      const orgData = await getOrganization(id);
      if (orgData) {
        fetchUsersProgressHide();
        return orgData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchAssetDetailApi(id) {
    try {
      // fetchUsersProgress();
      const orgData = await getAsset(id);
      if (orgData) {
        fetchUsersProgressHide();
        return orgData;
      }
    } catch (error) {
      fetchError();
    }
  }
  async function fetchOrganizationDeleteApi(id) {
    try {
      fetchUsersProgress();
      const result = await deleteOrganization(id);

      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchcustomerAssetsDeleteApi(record) {
    try {
      const result = await deleteCustomerAsset(record);
      if (result) {
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchAssetDeleteApi(id) {
    try {
      const result = await deleteAsset(id);
      if (result) {
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }
  async function fetchBulkOrganizationsDeleteApi(bulkIds) {
    try {
      fetchUsersProgress();
      const orgData = await deleteBulkOrganizations(bulkIds);
      if (
        orgData.data &&
        orgData.data.Message == "Organizations deleted successfully !"
      ) {
        const result = await fetchOrganizationsApi();
        return result;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkAssetsDeleteApi(bulkIds) {
    try {
      fetchUsersProgress();
      const orgData = await deleteBulkAssets(bulkIds);
      if (
        orgData.data &&
        orgData.data.Message == "Assets deleted successfully !"
      ) {
        const result = await fetchAssetsApi();
        return result;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkOrganizationsActivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const orgData = await activateBulkOrganizations(bulkIds);
      if (orgData) {
        fetchUsersProgressHide();
        return orgData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkOrganizationsDeactivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const orgData = await deactivateBulkOrganizations(bulkIds);
      if (orgData) {
        fetchUsersProgressHide();
        return orgData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  //Services Functions

  async function fetchServicesApi() {
    try {
      fetchUsersProgress();
      const servicesData = await fetchServicesData();
      if (servicesData) {
        fetchUsersProgressHide();
        return servicesData.services;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function addServiceApi(values) {
    try {
      fetchUsersProgress();
      const serviceData = await addService(values);
      if (serviceData) {
        fetchUsersProgressHide();
        return serviceData.data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function updateServiceApi(values, id) {
    try {
      fetchUsersProgress();
      const serviceData = await updateService(values, id);
      if (serviceData) {
        fetchUsersProgressHide();
        return serviceData.data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchServiceDetailApi(id) {
    try {
      fetchUsersProgress();
      const serviceData = await getService(id);
      if (serviceData) {
        fetchUsersProgressHide();
        return serviceData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchServiceDeleteApi(id) {
    try {
      fetchUsersProgress();
      const result = await deleteService(id);

      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkServicesDeleteApi(bulkIds) {
    try {
      fetchUsersProgress();
      const newList = await deleteBulkServices(bulkIds);
      if (
        newList.data &&
        newList.data.Message == "Services deleted successfully !"
      ) {
        const result = await fetchServicesApi();
        return result;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkServicesActivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const servicesData = await activateBulkServices(bulkIds);
      if (servicesData) {
        fetchUsersProgressHide();
        return servicesData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkServicesDeactivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const servicesData = await deactivateBulkServices(bulkIds);
      if (servicesData) {
        fetchUsersProgressHide();
        return servicesData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchCategoriesApi() {
    try {
      fetchUsersProgress();
      const fetchedCategoriesData = await getAssets();
      if (fetchedCategoriesData) {
        fetchUsersProgressHide();
        console.log(fetchedCategoriesData.Data);
        return fetchedCategoriesData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchServiceOptionsApi(service_id) {
    try {
      const fetchedServiceOptionsData = await serviceOptionsData(service_id);
      if (fetchedServiceOptionsData) {
        return fetchedServiceOptionsData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchServiceOptionDetailApi(id) {
    try {
      fetchUsersProgress();
      const serviceOptionData = await getOption(id);
      if (serviceOptionData) {
        fetchUsersProgressHide();
        return serviceOptionData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function addOptionApi(values, service_id) {
    try {
      fetchUsersProgress();
      const optionData = await addOption(values, service_id);
      if (optionData) {
        fetchUsersProgressHide();
        return optionData.data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function updateOptionApi(values, service_id, option_id) {
    try {
      fetchUsersProgress();
      const optionData = await updateOption(values, service_id, option_id);
      if (optionData) {
        fetchUsersProgressHide();
        return optionData.data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchOptionDeleteApi(option_id, service_id) {
    try {
      fetchUsersProgress();
      const result = await deleteServiceOption(option_id, service_id);
      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkOptionsDeleteApi(bulkIds, service_id) {
    try {
      fetchUsersProgress();
      const newList = await deleteBulkOptions(bulkIds, service_id);
      if (newList && newList.Message == "Options deleted successfully !") {
        const result = await fetchServiceOptionsApi(service_id);
        fetchUsersProgressHide();
        return result.options;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function addOrganizationServiceApi(values, queue_id) {
    try {
      fetchUsersProgress();
      const organizationService = await addOrganizationService(
        values,
        queue_id
      );
      if (organizationService) {
        console.log("organizationService", organizationService);
        return organizationService.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function updateOrganizationServiceApi(
    values,
    organization_id,
    service_id
  ) {
    try {
      fetchUsersProgress();
      const organizationService = await updateOrganizationService(
        values,
        organization_id,
        service_id
      );
      if (organizationService) {
        console.log("organizationService", organizationService);
        return organizationService.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchOrganizationServiceDeleteApi(
    service_id,
    organization_id
  ) {
    try {
      fetchUsersProgress();
      const result = await deleteOrganizationService(
        service_id,
        organization_id
      );
      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkOrganizationServicesDeleteApi(
    bulkIds,
    organization_id
  ) {
    try {
      fetchUsersProgress();
      const newList = await deleteBulkOrganizationService(
        bulkIds,
        organization_id
      );
      console.log("newList", newList);
      if (
        newList &&
        newList.Message == "Organization services deleted successfully !"
      ) {
        const result = await fetchOrganizationDetailApi(organization_id);
        return result;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkOrganizationServicesActivateApi(
    bulkIds,
    organization_id
  ) {
    try {
      fetchUsersProgress();
      const orgData = await activateBulkOrganizationServices(
        bulkIds,
        organization_id
      );
      if (orgData) {
        fetchUsersProgressHide();
        return orgData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkOrganizationServicesDeactivateApi(
    bulkIds,
    organization_id
  ) {
    try {
      fetchUsersProgress();
      const orgData = await deactivateBulkOrganizationServices(
        bulkIds,
        organization_id
      );
      if (orgData) {
        fetchUsersProgressHide();
        return orgData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchQueuesApi(id) {
    try {
      fetchUsersProgress();
      const queuesData = await fetchQueuesData(id);
      if (queuesData) {
        fetchUsersProgressHide();
        return queuesData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchQueueDetailApi(id) {
    try {
      fetchUsersProgress();
      const queueData = await getQueue(id);
      if (queueData) {
        fetchUsersProgressHide();
        return queueData.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function addQueueApi(values, queue_id) {
    try {
      fetchUsersProgress();
      const queue = await addQueue(values, queue_id);
      if (queue) {
        console.log("queue", queue);
        return queue.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function updateQueueApi(values, queue_id, organization_id) {
    try {
      fetchUsersProgress();
      const queue = await updateQueue(values, queue_id, organization_id);
      if (queue) {
        console.log("queue", queue);
        return queue.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchQueueDeleteApi(organization_id, queue_id) {
    try {
      fetchUsersProgress();
      const result = await deleteQueue(organization_id, queue_id);
      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkQueuesDeleteApi(bulkIds, queue_id) {
    try {
      fetchUsersProgress();
      const newList = await deleteBulkQueue(bulkIds, queue_id);
      console.log("newList", newList);
      if (newList && newList.Message == "Queues deleted successfully !") {
        const result = await fetchQueuesApi();
        return result;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkQueuesActivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const orgData = await activateBulkQueues(bulkIds);
      if (orgData) {
        fetchUsersProgressHide();
        return orgData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkQueuesDeactivateApi(bulkIds) {
    try {
      fetchUsersProgress();
      const orgData = await deactivateBulkQueues(bulkIds);
      if (orgData) {
        fetchUsersProgressHide();
        return orgData.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchOrganizationRequestsApi() {
    try {
      fetchUsersProgress();
      const organizationsData = await fetchOrganizationRequestsData();
      if (organizationsData) {
        fetchUsersProgressHide();
        return organizationsData.organizationRequests;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchOrganizationServices(
    selectedType,
    selectedOrganizationID
  ) {
    try {
      fetchUsersProgress();
      const organizationServices = await fetchOrganizationServicesAPI(
        selectedType,
        selectedOrganizationID
      );
      if (organizationServices) {
        fetchUsersProgressHide();
        return organizationServices.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBookingsApi() {
    try {
      fetchUsersProgress();
      const data = await bookingsData();
      if (data) {
        fetchUsersProgressHide();
        return data.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchStatusesApi() {
    try {
      fetchUsersProgress();
      const data = await getStatuses();
      if (data) {
        fetchUsersProgressHide();
        return data.Data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBookingDeleteApi(id) {
    try {
      fetchUsersProgress();
      const result = await deleteBooking(id);

      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function updateBookingStatusApi(values) {
    try {
      fetchUsersProgress();
      const result = await bookingStatus(values);

      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchTimelineAndTimslotApi(values) {
    try {
      fetchUsersProgress();
      const timelineData = await fetchTimelineData(values);
      const timeslotData = await fetchTimeslotData(values);
      console.log("timelineData", timelineData);
      console.log("timeslotData", timeslotData);

      var data = {};
      if (timelineData && timeslotData) {
        data = {
          timeline: timelineData,
          timeslot: timeslotData,
        };
        fetchUsersProgressHide();
        return data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function changesOrganizationRequestStatusApi(values, request_id) {
    try {
      fetchUsersProgress();
      const organizationRequest = await changeRequestStatus(values, request_id);
      if (organizationRequest) {
        fetchUsersProgressHide();
        return organizationRequest.data;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchOrganizationRequestDeleteApi(id) {
    try {
      fetchUsersProgress();
      const result = await deleteOrganizationRequest(id);

      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkOrganizationsRequestDeleteApi(bulkIds) {
    try {
      fetchUsersProgress();
      const orgData = await deleteBulkOrganizationRequests(bulkIds);
      if (
        orgData.data &&
        orgData.data.Message == "Organization requests deleted successfully !"
      ) {
        const result = await fetchOrganizationRequestsApi();
        return result;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchBulkBookingsDeleteApi(bulkIds) {
    try {
      fetchUsersProgress();
      const orgData = await deleteBulkBookings(bulkIds);
      if (
        orgData.data &&
        orgData.data.Message == "Bookings deleted successfully !"
      ) {
        const result = await fetchBookingsApi();
        return result;
      }
    } catch (error) {
      fetchError();
    }
  }

  async function fetchNotificationsApi() {
    try {
      fetchUsersProgress();
      const notificationsData = await fetchAllNotifications();
      if (notificationsData) {
        fetchUsersProgressHide();
        return notificationsData.notifications;
      }
    } catch (error) {
      fetchError();
    }
  }
  async function addNotificationApi(values) {
    try {
      fetchUsersProgress();
      const result = await addNotification(values);
      return result.data;
    } catch (error) {
      fetchError();
    }
  }

  async function fetchNotificationDeleteApi(id) {
    try {
      console.log("GLOBAL :", id);
      fetchUsersProgress();
      const result = await deleteNotification(id);

      if (result) {
        fetchUsersProgressHide();
        return result.data.Message;
      }
    } catch (error) {
      fetchError();
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        users: state.users,
        loading: state.loading,

        fetchUsersProgressHide,
        fetchError,

        fetchUsersApi,
        addUserApi,
        fetchUserDeleteApi,
        updateUserApi,

        fetchUserDetailApi,
        fetchBulkUsersDeleteApi,
        fetchBulkUsersActivateApi,
        fetchBulkAssetsActivateApi,
        fetchBulkUsersDeactivateApi,
        fetchBulkAssetsDeactivateApi,
        fetchBulkcustomerAssetsDeactivateApi,
        fetchBulkcustomerAssetsActivateApi,
        fetchVehiclesDataApi,
        addVehicleApi,
        updateVehicleApi,
        fetchVehicleDeleteApi,

        fetchOrganizationsApi,
        fetchAssetsApi,
        fetchcustomerAssetsApi,
        addOrganizationApi,
        addAssetApi,
        fetchOrganizationDetailApi,
        fetchAssetDetailApi,
        updateOrganizationApi,
        updateAssetApi,

        fetchOrganizationDeleteApi,
        fetchAssetDeleteApi,
        fetchcustomerAssetsDeleteApi,
        fetchBulkOrganizationsDeleteApi,
        fetchBulkAssetsDeleteApi,
        assetStatusChangeApi,
        customerAssetStatusChangeApi,
        fetchBulkOrganizationsActivateApi,
        fetchBulkOrganizationsDeactivateApi,
        fetchBulkcustomerAssetsDeleteApi,
        fetchServicesApi,
        addServiceApi,
        updateServiceApi,
        fetchServiceDetailApi,
        fetchServiceDeleteApi,
        fetchBulkServicesDeleteApi,
        fetchBulkServicesActivateApi,
        fetchBulkServicesDeactivateApi,

        fetchCategoriesApi,
        fetchServiceOptionsApi,
        fetchServiceOptionDetailApi,
        addOptionApi,
        updateOptionApi,
        fetchOptionDeleteApi,
        fetchBulkOptionsDeleteApi,

        addOrganizationServiceApi,
        updateOrganizationServiceApi,
        fetchOrganizationServiceDeleteApi,
        fetchBulkOrganizationServicesDeleteApi,
        fetchBulkOrganizationServicesActivateApi,
        fetchBulkOrganizationServicesDeactivateApi,

        fetchQueuesApi,
        fetchQueueDetailApi,
        addQueueApi,
        updateQueueApi,
        fetchQueueDeleteApi,
        fetchBulkQueuesDeleteApi,
        fetchBulkQueuesActivateApi,
        fetchBulkQueuesDeactivateApi,

        fetchOrganizationRequestsApi,
        fetchOrganizationServices,
        fetchBookingsApi,
        fetchBookingDeleteApi,
        fetchBulkBookingsDeleteApi,
        fetchStatusesApi,
        updateBookingStatusApi,

        fetchTimelineAndTimslotApi,
        changesOrganizationRequestStatusApi,
        fetchOrganizationRequestDeleteApi,
        fetchBulkOrganizationsRequestDeleteApi,

        fetchNotificationsApi,
        addNotificationApi,
        fetchNotificationDeleteApi,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
