export default (state, action) => {
  switch (action.type) {
    case "FETCH_USERS_PROGRESS":
      return {
        ...state,
        loading: true,
      };
    case "FETCH_USERS_PROGRESS_HIDE":
      return {
        ...state,
        loading: false,
      };
    case "FETCH_ERROR":
      return {
        loading: false,
        users: [],
        error: "Something went wrong",
      };
    default:
      return state;
  }
};
